

import Component from "vue-class-component";
import Popup from "~/ui/Popup/Popup.vue";
import {Emit} from 'nuxt-property-decorator'
import DocPassport from "@pp-frontend/shared-packages/classes/doc-value/DocPassport";
import Input from "~/components/ui/Input.vue";
import InputDate from "~/components/ui/InputDate.vue";
import SearchFormAbstract from "@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract";
import OpenPdf from "~/components/ui/openPdf.vue";
import {FieldsId} from "@pp-frontend/shared-packages/types/enum/FieldsId";
import ButtonLoader from "~/components/ui/buttonLoader.vue";


@Component({
  components: {OpenPdf, InputDate, Input, Popup, ButtonLoader}
})
// @ts-ignore
export default class PopupInn extends SearchFormAbstract {

  error: boolean = false
  // text: string = 'Через данные вашего паспорта узнаем и покажем ваш ИНН'
  loading: boolean = false
  docTypeList:any = [
      {
          text: 'По паспорту',
          name: 'passport',
          value: new DocPassport(),
          fieldId: FieldsId.PASPORT
      },

  ]

  @Emit()
  sendDataForInn(val) {
  }

  @Emit()
  close() {}
  get formData () {
    return this.docTypeList[0].value
  }
  // @ts-ignore
  async submit () {
    // @ts-ignore
    if (!this.$v.$touch() && this.$v.$anyError){
      return
    }
    this.error = false
    this.loading = true
    let data = await this.$axios.post(process.env.API_ENDPOINT + 'fns/inn-by-passport', {
      document_type: this.formData.docType(),
      document_value: this.formData.document_value.document_value,
      owner_name: this.formData.document_value.fio,
      additional_fields: {
        birth_date: this.formData.document_value.birth_date.split('.').reverse().join('-')
      }
    }).then(response => {
      this.sendDataForInn(response.data.document_value)
    }).catch(() => {
      this.error = true
    }).finally(() => {
      this.loading = false
    })
  }
}
